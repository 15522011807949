$color-orange: #f2711c;
$color-grey: #666;
$color-black: #333;
$color-yellow: #ffec00;
$header-height: 60px;

html {
  overflow: auto;
}
html,body {
  background-color: #0d5f76;
  // background-color: $color-black;
  margin: 0;
  padding: 0;
  height: 100%;
}

html,body,div,input,header {
  color: #081d3f !important;
  font-family: 'Montserrat', sans-serif !important;
}

.App-header {
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: right;
  color: #081d3f !important;
  height: #{$header-height};
  line-height: #{$header-height};
  position: relative;
}

#homeContent {
  margin-top: 5px;
}

#chatSegment {
  margin:0;
  padding:0;
}

.App-title {
  font-size: 1.5em;
  font-weight: 600;
}

.botResponse p {
    text-align:left;
}
.userResponse p {
  text-align:right;  
}

.conversation {
  height: calc(100vh - 250px);
  min-height: 100px;
}

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
  height: calc(100% - 60px);
}

.style-button {
  border-radius: 20px!important;
  background-color: #0d5f76!important;
  color:white!important;
}
.selection-button {
  height: 40px;
  width: 100%;
  text-transform: uppercase !important;
  min-width: fit-content;
}

.send-msg-button {
  height: 40px;
  min-width: fit-content;
}
.chatInput {
  padding-right: 55px;
  >input {
    border-radius: 30px !important;
  }
}
.chatTextarea {
  min-height: 40px;
  height: 100%;
  line-height: 1; // this lineheight has to be the same in chat.jsx to get textarea height
  border-radius: 30px !important;
  resize:none;
  width: 100%;
  overflow: hidden;

  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  text-align: left;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 16px;
  padding: .67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  color: rgba(0,0,0,.87);
  -webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
  transition: border-color .1s ease,-webkit-box-shadow .1s ease;
  transition: box-shadow .1s ease,border-color .1s ease;
  transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  z-index: 1;
  white-space:pre-line;
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.message:first-child {
  margin: 16px 0 8px;
}

.message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.message.received .metadata {
  padding: 0 0 0 16px;
}

.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.custom-tooltip {
  background-color: #c8cfda5c;
}
.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.right-col {
  max-width: 400px;
}

.custom-area {
  width: 100% !important;
  height: unset !important;
  .recharts-surface {
    width: 100% !important;
    max-height: 400px;
  }
}
.recharts-surface {
  overflow: visible !important;
}

.inline-div {
  display: inline-block;
  margin:10px 15px;
  min-width: 350px;
  max-width: 400px;
}

.main-content {
  margin-left:260px;
  margin-right:10px;
}

#buttonsDiv {
  position: absolute;
  bottom:0;
  left:0;
  right:0;
  padding: 15px 0;
  background-color:white;
  text-align:center;
  z-index: 99999;
  #sendImage {
    background-color: #0d5f76;
    border-radius: 40px;
  }
}
#titleHeader {
  padding-top:10px;
}

#progressBar {
  .bar {
    // background-color: #8884d8;
    background-color: #3caea3;
  }
}
.homeLink {
  display: inline;
}


.typing-indicator {
  $ti-color-bg: #E6E7ED;
  will-change: transform;
  display: table;
  margin: 0 auto;
  position: relative;
  animation: 2s bulge infinite ease-out;
  span {
    height: 5px;
    width: 5px;
    float: left;
    margin: 0 1px;
    background-color: #9E9EA1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * .3333s);
      }
    }
  }
}
@keyframes blink {
  50% {
    opacity: 1;
  }
}
@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}


.blur-effect {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: #fff;
  -webkit-transform: translate3d(0, 0, 0);
  object-fit: contain;
  transition: .5s -webkit-filter linear;
}
#chatWrapper {
  margin:0;
  font-family: 'GoogleSans';
  font-size: 13px !important;
  line-height: 1.38 !important;
  letter-spacing: 0.26px !important;
  border:none;

  #chatSegment {
    padding-top:5px;
    margin:0;
    height: calc(100vh - #{$header-height});
    height: calc(( var(--vh) * 100) - #{$header-height} );
  }
  .conversation {
    height: calc(100vh - #{$header-height} - 10px - 96px);
    height: calc((var(--vh) * 100) - (#{$header-height} + 10px) - var(--txth));
    overflow-x: hidden;
    padding: 0px;
    .conversation-container {
      margin:0;
      padding-top: 19px;
      padding-bottom: 19px;
      height:100%;
    }
  }
  .only-buttons-div {
    overflow-x:auto;
    white-space:nowrap;
    &::-webkit-scrollbar {
      display: inline;
    }
  }
  .chatMsg {
    color: #000;
    clear: both;
    padding: 12px 17px 10px 17px;
    position: relative;
    min-width: 42px;
    max-width: 85%;
    word-wrap: break-word;
    z-index: 1;
    white-space:pre-line;
    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
    }

    &.received {
      // background: #f0f0f0;
      background: #edf4fb;
      border-radius: 20px;
      float: left;
      color: #1e1e1e;
      min-height: 40px;
      -moz-transform:rotate(0);
      -webkit-transform:rotate(0);
      -ms-transform:rotate(0);
      -o-transform:rotate(0);
      transform:rotate(0);
      &:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 6px 10px 6px 0;
        // border-color: transparent #f0f0f0;
        border-color: transparent #edf4fb;
        display: block;
        width: 0;
        z-index: 0;
        left: -3.5px;
        bottom: -1.5px;
        transform: rotate(-40deg);
      }
    }

     &.sent {
      position: relative;
      background: #ffffff;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      float: right;
      color: #1e1e1e;
      border: solid 1px #e1e1e1;
      &:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 6px 0 6px 10px;
        border-color: transparent #e1e1e1;
        display: block;
        width: 0;
        z-index: 0;
        right: -4px;
        bottom: -2px;
        transform: rotate(40deg);
      }
      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 5px 0 5px 9px;
        border-color: transparent #ffffff;
        display: block;
        width: 0;
        z-index: 1;
        right: -2.3px;
        bottom: 0px;
        transform: rotate(40deg);
      }
    }
  }
}


/* 
#### write here specific template rules ####
kwell template
*/ 
.wrapper_kwell {
  #chatWrapper {
    .chatMsg {
      &.received {
        background: #f0f0f0;
      }
    }
    #buttonsDiv {
      #sendImage {
        background-color: #000;
      }
    }
  }

  .style-button {
    background-color: #000!important;
  }
}
// #### end template rules ####

#footer {
  position: absolute;
  margin:0;
  padding:0;
  bottom:0;
  right:0;
  left:0;
  z-index: 1;
  height: 96px;
  height: var(--txth);
  #buttonsDiv {
    border-radius: 4px 4px 0 0;
  }
}

.custom-tooltip {
  background-color: #c8cfda5c;
}

.right-col {
  max-width: 400px;
}

.custom-area {
  width: 100% !important;
  height: unset !important;
  .recharts-surface {
    width: 100% !important;
    max-height: 400px;
  }
}
.recharts-surface {
  overflow: visible !important;
}

.inline-div {
  display: inline-block;
  margin:10px 15px;
  min-width: 350px;
  max-width: 400px;
}


#buttonsDiv {
  padding: 10px 0;
  background-color:white;
  text-align:center;
  border-top: 1px solid #e1e1e1;
  height: 100%;
}

#progressBar {
  .bar {
    // background-color: #8884d8;
    background-color: #3caea3;
  }
}
.homeLink {
  display: inline;
}

.phrasesDiv {
  border: 1px solid lightgrey;
  overflow-y: auto ;
  overflow-x: hidden ;
  max-height: 170px;
  .ui.input > input {
    background-color: lightgrey;
  }
  div.fields {
    margin:10px 0px !important;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #cce2ff;      
  }
  &::-webkit-scrollbar-thumb {
    background-color: #0d5f76;
  }
}

.typing-indicator {
  $ti-color-bg: #E6E7ED;
  will-change: transform;
  display: table;
  margin: 21% auto 0 auto;
  position: relative;
  animation: 2s bulge infinite ease-out;
  span {
    height: 6px;
    width: 6px;
    float: left;
    margin: 0 1px;
    background-color: #1e1e1e;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * .3333s);
      }
    }
  }
}
@keyframes blink {
  50% {
    opacity: 1;
  }
}
@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}

@media only screen and (max-width: 991px) {

  #homeContent {
    margin-top: 0px;
    border-radius: 0px;
  }


  #rootContainer {
    padding:0;
    margin:0 !important;
    width: 100% !important;
  }

  #titleHeader {
    padding: 0px;
  }

  #chatPageContainer {
    margin:0 !important;
    padding:0;
    width: 100% !important;
  }

  div.ui.stackable.grid {
    margin:0;
    >div.row {
      >div.column {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .App-header {
    border-radius: 0px;
    border-bottom: 1px solid #e1e1e1;
    padding: 0;
    margin: 0;
  }

  #chatWrapper {
    margin:0;
    .segment {
      border-radius: 0;
    }
    #chatSegment {
      padding:0;
    }
    .conversation {
      height: calc(100vh - #{$header-height} - 96px);
      height: calc((var(--vh) * 100) - #{$header-height} - var(--txth));
    }
    .only-buttons-div {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  #footer {
    #buttonsDiv {
      border-radius: 0;
    }
  }

  .left-col {
    margin-bottom: 30px !important;
  }

  .right-col {
    max-width: none;
  }

  .width100 {
    width: 100% !important;
  }

  .main-content {
    margin-left:10px;
    margin-right:10px;
  }

  .responsive-menu {
    .ui.secondary.vertical.pointing.menu{
      border-bottom-width: 0;
      border-right: none;
      border-left-width: 2px;
      border-left-style: solid;
      border-left-color: rgba(34,36,38,.15);
    }
    .ui.secondary.vertical.pointing.menu .item {
      border-right: none;
    }
    .ui.secondary.vertical.pointing.menu .active.item {
      border-left: 2px solid #1b1c1d !important;
      margin-left: -2px;
    }
  }
}